<template>
  <b-container data-aos="fade-up">
    <b-img-lazy
      class="w-100"
      alt="One of the drivers sat with a passenger"
      src="../../assets/passengers.jpg"
    />
    <h1 class="mt-5 mb-4 text-primary">Guidance for Passengers</h1>
    <p>
      <i
        >We provide a community transport service in the villages of Thorney,
        Harby, Wigsley and surrounding villages (N &amp; S Clifton and Spalford)
        to enable residents of all ages to access health related services,
        shopping amenities, leisure and social activities during the day or
        evenings.
      </i>
    </p>
    <p>
      Journeys will be charged at 45p per mile with an additional charge of £2
      per journey if the driver lives in a different village from the passenger.
      (This £2 charge is standard and acknowledges the mileage covered from the
      driver's home and back once the journey has been completed.)
    </p>
    <h2 class="text-primary">To book a journey</h2>
    <p>
      Please contact the Scheme Co-ordinator, Mrs Clare Welfare on
      <a href="tel:07717178800">07717 178800</a> by either phone or text message
      or send an email. It's helpful to give as much notice as you can, ideally
      48 hrs. Every effort will be made to find a volunteer driver but this may
      not always be possible.
    </p>
    <p>Please be prepared to provide the following information:</p>
    <ul>
      <li>Your name and where you want to go to.</li>
      <li>What time do you need to be there?</li>
      <li>
        Are you to be collected from your home address/provide details and phone
        number?
      </li>
      <li>Do you need a return journey or not?</li>
      <li>
        Are you the only passenger or will any other people need to travel with
        you?
      </li>
      <li>
        Do you have any specific health issues that a driver needs to be made
        aware of?
      </li>
      <li>
        Do any mobility aids or other items need to be transported with you?
      </li>
      <li>
        It is helpful but not essential for passengers to have a mobile phone
        with them. This would enable you to contact a driver if your pick up
        time is delayed e.g. from an appointment you have been attending.
      </li>
    </ul>
    <p>
      Journeys will normally be in the local area of the named villages as well
      as Saxilby, Lincoln, Collingham, Gainsborough and Newark. Longer trips may
      be possible but this will be at the discretion of the Coordinator and
      dependent on driver availability. Last pickups are no later than 11pm
      unless with prior agreement of the co-ordinator
    </p>
    <h2 class="text-primary">The Volunteer Driver</h2>
    <p>
      Your name/address and phone number and relevant details will be passed on
      to a volunteer driver who will contact you to agree what time they will
      arrive to collect you. Please keep a lookout for the driver as in rural
      villages some properties are not always easy to locate. All volunteers
      will carry a Car Scheme Identity Card and should let you view this before
      you get into their car. Their vehicle will have relevant insurance to
      cover you as a passenger.
    </p>
    <h2 class="text-primary">Payment for the journey</h2>
    <p>
      The driver is to be paid at the end of your journey at the rate of 45p per
      mile (+£2 if the driver does not live in the same village as yourself).
      Any car parking charges incurred as part of your journey must be paid for
      by yourself eg. hospital visiting when the driver has agreed to wait for
      you. The driver will confirm the total cost to you. It would be helpful if
      you could have the correct cash (as near as possible) as drivers may find
      it difficult to give change if you only have notes. Drivers will not
      accept tips but any small surplus cash given will be donated back to the
      Car Scheme to assist with overall running costs.
    </p>
    <h2 class="text-primary">General Safeguards</h2>
    <ul>
      <li>
        Your personal details will be held in confidence and essential
        information only passed to a volunteer driver to enable then to
        undertake a journey for you (<b-link to="/privacy-policy">Please see our attached GDPR policy</b-link>).
      </li>
      <li>
        All drivers will have DBS (police check) clearance and appropriate
        insurance that covers you as a passenger.
      </li>
      <li>
        All drivers have been advised it is inappropriate for them to:
        <ul>
          <li>
            Pay bills, collect pensions, fill in forms on your behalf and they
            may not assist in writing a will or acting as a witness for you.
          </li>
          <li>Give advice on benefits.</li>
          <li>
            Accept gifts from you except low value items eg. flowers /
            chocolates.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      Please remember all drivers are volunteers giving up their time to help
      you. Please show them the same courtesy that they will show you. Any
      concerns should be
      <b-link to="/contact-us"
        >reported to the Coordinator, Clare Welfare</b-link
      >
      at the conclusion of your journey.
    </p>
  </b-container>
</template>

<script>
export default {
  name: "Passengers",
};
</script>
